import { isLiveBlogPath } from 'lib/liveBlog';
import { getBodyItemComponent } from '../getBodyItemComponent';

const TABOOLA = 'taboola';
const BOX_RAIL = 'boxrail';
const BOX_FLEX = 'boxflex';

const isContentElement = (item) => !!item
  && (item.element === 'p'
    || item.element === 'ul'
    || item.element === 'ol'
    || (item.presentation?.size
      && ['small', 'medium'].includes(item.presentation.size)));

/**
 *
 * @param {*} section
 * @returns {number}
 */
const getContentCount = (section) => section.filter(isContentElement).length;

// eslint-disable-next-line max-len
const getComponentWrapper = ({
  path,
  articlePublishDate,
  vertical,
  lazyLoadSection,
  isArticleEcommerceEnabled,
  articleId,
  articleTrackingId,
}) => (item, i) =>
// eslint-disable-next-line implicit-arrow-linebreak
  getBodyItemComponent({
    isArticleEcommerceEnabled,
    item,
    i,
    path,
    articlePublishDate,
    vertical,
    lazyLoadSection,
    articleId,
    articleTrackingId,
  });

const getAdsToDisplay = (contentLength) => {
  // 0-2 paragraphs: no ads
  if (contentLength < 3) return [];

  // 3-4 paragraphs: boxrail only
  if (contentLength < 5) return [BOX_RAIL];

  // 5-6 paragraphs: taboola & boxrail
  if (contentLength < 7) return [TABOOLA, BOX_RAIL];

  // 7+ paragraphs: taboola & boxflex
  return [TABOOLA, BOX_FLEX];
};

/**
 *
 * @param {string} path
 * @param {string} articlePublishDate
 * @param {VerticalType} vertical
 * @param {isShoppable} boolean
 * @param {isArticleEcommerceEnabled} boolean
 * @returns
 */
export function formatSections({
  path,
  articlePublishDate,
  isArticleEcommerceEnabled,
  vertical,
  isShoppable,
  articleId,
  articleTrackingId,
  gateAccess = true,
}) {
  return (sections) => sections.map((sec, i) => {
    let section = [];
    if (gateAccess) {
      section = sec;
    } else if (i === 0) {
      // grab only the first two sections for gated articles
      section = [sec[0], sec[1]];
    }

    const contentCount = getContentCount(sec);
    const isFullWidth = sec.every((s) => s.presentation?.size === 'edgetoedge');
    const ads = getAdsToDisplay(contentCount);
    const isLiveBlog = isLiveBlogPath(path);
    // only consider eager loading of images for the first section
    const lazyLoadSection = i > 0;

    const formatted = {
      items: section.map(
        getComponentWrapper({
          path,
          articlePublishDate,
          vertical,
          lazyLoadSection,
          isArticleEcommerceEnabled,
          articleId,
          articleTrackingId,
        }),
      ),
      isFullWidth,
      ad: {
        display: false,
      },
      taboola: false,
    };

    if (!ads.length) return formatted;

    if (!isShoppable && (ads.includes(TABOOLA) || isLiveBlog)) {
      formatted.taboola = true;
    }

    if (ads.includes(BOX_RAIL)) {
      formatted.ad.display = true;
      formatted.ad.slot = BOX_RAIL;
    }

    if (ads.includes(BOX_FLEX)) {
      formatted.ad.display = true;
      formatted.ad.slot = BOX_FLEX;
    }

    return formatted;
  });
}
